import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import useRequest from "kb-gql";
import MenuLinks from "./components/MenuLinks";
import Logo from "../../assets/icons/Logo";
import Hamburger from "./components/Hamburger";
import { colors, typography } from "../../const";
import useScroll from "../../hooks/useScroll";
import useKey from "../../hooks/useKey";
import { Link as A } from "react-router-dom";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import useWindowSize from "../../hooks/useWindowSize";
import { gutterWidth } from "../../components/Grid";

const query = `
    siteSettings {
		fields {
			header {
				mainMenu {
					link{
						title
						url
					}
				}
				subMenu{
					link{
						title
						url
					}
				}
			}
		}
    }
`;

const Header = ({
  isSearchOpen,
  primaryColorScheme,
  scrollPos,
  footerRef,
  isSafari,
  /* isEmployeeIndexPage,
    isEmployeeFormOpen,
    employeeFormOpenScrollPosition, */
}) => {
  const menuRef = useRef(null);
  const headerItems = useRequest(query, "_global_header", "fields.header");
  const [menuOpen, setMenuOpen] = useState(false);
  const [archivedScrollPosition, saveScrollPosition] = useState(0);
  const [topOffset, setTopOffset] = useState(0);
  const windowSize = useWindowSize().width;
  const { y, direction } = useScroll();
  const esc = useKey("escape");
  const [keyboarding, isKeyboarding] = useState(false);
  useOnClickOutside(menuRef, () => setMenuOpen(false));

  const anyKey = useCallback((event) => {
    if (event.keyCode == 9 && !keyboarding) {
      isKeyboarding(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", anyKey, false);

    return () => {
      document.removeEventListener("keydown", anyKey, false);
    };
  }, []);

  useEffect(() => {
    if (archivedScrollPosition > 0 && scrollPos !== archivedScrollPosition)
      setMenuOpen(false);
    checkOffsetHeight(
      document.body.scrollHeight,
      footerRef?.current?.offsetHeight + 162,
      scrollPos,
      window.innerHeight,
      menuRef?.current?.offsetHeight
    );
  }, [scrollPos]);

  const checkOffsetHeight = (
    bodyHeight,
    footerHeight,
    scrollPos,
    clientHeight,
    headerHeight
  ) => {
    let triggerPoint = bodyHeight - footerHeight;

    if (triggerPoint <= scrollPos + headerHeight) {
      setTopOffset(scrollPos + headerHeight - triggerPoint);
    } else {
      setTopOffset(0);
    }
  };
  let scrolling = y > 64;

  if (esc && menuOpen) setMenuOpen(false);

  return (
    <HeaderWrapper
      className={`${!primaryColorScheme && " secondary-background "} ${
        menuOpen ? " menu-open " : " menu-closed "
      } `}
      ref={menuRef}
      topOffset={topOffset}
      //isEmployeeFormOpen={isEmployeeFormOpen}
      //employeeFormOpenScrollPosition={employeeFormOpenScrollPosition}
    >
      <div className="mobile-background" />
      <div
        className={`
                    logo-wrapper  
                    ${scrolling ? `scrolling-${direction}` : ""} 
                    ${isSearchOpen ? "search-open" : ""}
                `}
      >
        <Hamburger
          className="mobile-menu"
          onClick={() => {
            setMenuOpen(!menuOpen);
            saveScrollPosition(scrollPos);
          }}
          open={menuOpen}
          tabIndex="1"
        />
        <A
          className={`logo ${scrolling ? `scrolling-${direction}` : ""}`}
          to="/"
          onClick={() => setMenuOpen(false)}
          tabIndex="1"
        >
          <Logo />
        </A>
      </div>
      {keyboarding && (
        <ContentJump tabIndex={1} href={"#main-content"}>
          Hopp til hovedinnhold
        </ContentJump>
      )}
      <MenuLinks
        mainMenu={headerItems?.mainMenu}
        subMenu={headerItems?.subMenu}
        closeMenu={setMenuOpen}
        isSearchOpen={isSearchOpen}
        className="main-menu"
        shouldBeFocusable={!isSearchOpen && (windowSize > 1440 || menuOpen)}
      />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 67px 67px 0;
  z-index: 10;

  .logo {
    &.safari {
      top: 36px !important;
    }
  }

  .main-menu {
    margin-top: 127px;
    padding: 0;
  }

  @media (min-width: 1441px) {
    padding: 67px 0 0 67px;
    transition: top 0.2s linear;

    .main-menu {
      margin-top: 67px;
    }

    ${({ topOffset }) =>
      topOffset !== 0
        ? `
			position: fixed;
			top: -${topOffset}px;
		`
        : `
			top: 0;
		`}

    ${({ isEmployeeIndexPage }) =>
      isEmployeeIndexPage
        ? `
                transition: none;
                ${({ isEmployeeFormOpen, employeeFormOpenScrollPosition }) =>
                  isEmployeeFormOpen && employeeFormOpenScrollPosition
                    ? `
                        top: -${employeeFormOpenScrollPosition}px;
                    `
                    : ``}
            `
        : ``};
  }

  @media (max-width: 1440px) {
    padding: 67px 0 0;
    .logo-wrapper {
      display: flex;
      align-items: flex-end;
      padding: 0 44px;
      .mobile-menu {
        display: block;
      }
      .logo {
        display: inline-flex;
        margin-left: 76px;
        position: fixed;
        top: 60px;
        &.scrolling-up {
          top: -100%;
        }
        &.scrolling-down {
          top: -100%;
          transition: top 0.5s ease-in;
        }
      }
    }
    &.menu-closed {
      .main-menu {
        height: 0;
      }
    }
    .mobile-background,
    .main-menu {
      transition: all 0.4s ease-in-out;
    }
    .main-menu {
      opacity: 0;
      transform: translateX(-25%);
    }
    .mobile-background {
      height: calc(100vh + 67px);
      width: 100%;
      top: -67px;
      position: absolute;
      z-index: -1;
      transform: translateX(-100%);
      background-color: ${colors.white};
      margin-left: -16px;
    }
    &.secondary-background {
      .mobile-background {
        background-color: ${colors.tertiary};
      }
    }
    &.menu-open {
      height: 100vh;
      .mobile-background {
        transform: translateX(0);
      }
      .main-menu {
        height: 100%;
        transform: translateX(0);
        opacity: 1;
        padding: 0 44px;
      }
      &.secondary-background {
        transition: none;
      }
    }
  }

  @media (max-width: 1440px) and (min-width: 1025px) {
    height: 60px;
    &.menu-open {
      .logo {
        top: 60px !important;
        transition: top 0.3s ease-in;
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 0;
    display: flex;
    width: 100%;
    height: unset;

    nav {
      height: 0;
    }

    &.menu-open {
      height: 100vh;
      opacity: 1;

      .main-menu {
        padding: 0 ${gutterWidth / 2}px;
      }
    }

    .mobile-background {
      margin-left: 0;
    }

    .logo-wrapper {
      position: absolute;
      padding: 0;
      padding-bottom: 20px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      position: fixed;
      top: 0;
      &.scrolling-up {
        transition: top 0.6s ease-out;
      }
      &.scrolling-down {
        top: -100%;
        transition: top 0.6s ease-in;
      }
      &.search-open {
        background-color: transparent;
        transition: background-color 0s;
      }
      .mobile-menu {
        margin-right: ${gutterWidth / 2}px;
        margin-top: 49px;
        order: 2;
      }
      .logo {
        position: absolute;
        left: 0;
        top: unset;
        order: 1;
        margin-left: ${gutterWidth / 2}px;
        &.scrolling-up {
          top: unset;
          transition: none;
        }
        &.scrolling-down {
          top: unset;
          transition: none;
        }
      }
    }
  }

  @media (max-width: 768px) {
    &.menu-open {
      .main-menu {
        padding: 0 25px;
      }
    }
    .logo-wrapper {
      .logo {
        margin-left: 25px;
        left: 0;
      }
      .mobile-menu {
        margin-right: 25px;
        margin-top: 30px;
        order: 2;
      }
    }
  }
`;

const ContentJump = styled.a`
  font-family: ${typography.mono};
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 6px;
  color: ${colors.black};
  text-decoration: none;
  transition: color 0.2s linear;
  &.active {
    border-bottom: solid 1px rgba(0, 0, 0, 0.6);
    &:hover {
      text-decoration: none;
    }
  }
  &:hover {
    color: rgba(0, 0, 0, 0.6);
  }
  &:focus {
    color: rgba(0, 0, 0, 0.6);
  }
`;
export default Header;
